<template>
  <layout-operator-auth title="core.404.title">
    <div class="home">
      <hr />
      <h2 class="text-center width-expand margin-l-top margin-l-bottom">
        {{ translate("core.404.title") }}
      </h2>
      <p class="text-l text-center margin-xl-bottom">
        {{ translate("core.404.explain") }}
      </p>
      <div class="flex flex-center width-expand">
        <form-button
          theme="lead"
          :full-width="true"
          icon="chevron-inline-start"
          @click="goHome"
          >{{ translate("core.404.buttonCta") }}
        </form-button>
      </div>
    </div>
  </layout-operator-auth>
</template>

<script>
export default {
  name: "PageNotFound",

  components: {},
  methods: {
    goHome() {
      if (!this.userIsLoggedIn) {
        return this.$router.push({ name: "auth" });
      }

      if (this.userIsSuperAdmin) {
        return this.$router.push({ name: "operator-admin" });
      }

      if (this.userIsOperator) {
        return this.$router.push({ name: "operator-dashboard" });
      }

      // this is a selfer, then
      return this.$router.push({ name: "selfer" });
    },
  },
};
</script>
